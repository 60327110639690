import api from '../axios'

const resource = 'api/super-admin/locums'

export default {
  getLocums: (page, paginate,filter) => api.get(`${resource}?page=${page}&paginate=${paginate}&filter[file_state]=${filter}`),
  getLocum: (id) => api.get(`${resource}/${id}`),

  // getDoc: () => api.get(`${resource}/{locum_id}/documents/{document_id}/show`),
  approveLocum: (locumId,documentId) => api.post(`${resource}/${locumId}/documents/${documentId}/approve`),
  declineLocum: (locumId,documentId) => api.post(`${resource}/${locumId}/documents/${documentId}/decline`),

}
